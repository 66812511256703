import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FieldsForm from './FieldsForm'
import PhoneConfirmation from './PhoneConfirmation'
import useUserData, {
	AcceptPhoneActivationCodeData,
	PhoneConfirmationVariant,
} from '../../stores/userData'
import useAuth from './useAuth'
import validatePhoneAndPassword from './validatePhoneAndPassword'
import yandexTracking from '../../utils/yandexTracking'
import { useQueryParams } from 'src/utils'
import useResponsive from 'src/utils/useResponsive'
import { SignUpInfo } from './SignUpInfo'
import s from './authstyles/signUpForm.module.scss'

type RegisterQueryParams = {
	utm_source: string
}

const getTitleMargin = (isMobile: boolean, step: number) => {
	if (isMobile) {
		switch (step) {
			case 0:
				return '2rem'
			case 1:
				return '0.75rem'
		}
	} else {
		switch (step) {
			case 0:
				return '2.25rem'
			case 1:
				return '1rem'
		}
	}
}

export default function RegisterForm({ cn }) {
	const { params } = useQueryParams<Partial<RegisterQueryParams>>()

	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const [step, setStep] = useState(0)
	const [user, userInterface] = useUserData()
	const { errors, handleSubmit } = useAuth(
		async (data) => {
			const dataWithSource = { ...data }

			if (params?.utm_source) {
				dataWithSource.utm_source = params.utm_source
			}

			setIsLoading(true)
			await userInterface.register(dataWithSource)
			yandexTracking.setUser(data)
			await yandexTracking.reachGoal('set_phone_and_pass')
		},
		() => {
			setIsLoading(false)
		}
	)

	useEffect(() => {
		if (user.phone_number && !user.is_phone_confirmed && step !== 1) {
			setStep(1)
		}
	}, [user, step])

	async function handlePhoneConfirmation(
		data: AcceptPhoneActivationCodeData
	) {
		await userInterface.acceptPhoneActivationCode(data)
		await yandexTracking.reachGoal('accept_phone')
		history.push('/campaigns/')
	}

	async function handleCodeResend(variant: PhoneConfirmationVariant) {
		await userInterface.sendPhoneActivationCode(variant)
	}

	const { isMobile } = useResponsive()
	const titleMargin = getTitleMargin(isMobile, step)

	return (
		<>
			<h1 style={{ marginBottom: titleMargin, textAlign: 'center' }}>
				Создание аккаунта
			</h1>

			{(() => {
				switch (step) {
					case 0:
						return (
							<FieldsForm
								isLoading={isLoading}
								errors={errors}
								onValidate={validatePhoneAndPassword}
								fields={[
									{
										name: 'phone_number',
										autoComplete: 'tel',
										label: 'Телефон',
										placeholder: '+7 (900) 000-00-00',
									},
									{
										name: 'password',
										autoComplete: 'current-password',
										label: 'Пароль',
										text: 'Пароль должен содержать не менее восьми знаков, включать буквы, цифры и специальные символы',
										placeholder: '••••',
										toggleVisibility: true,
									},
								]}
								submitLabel={'Продолжить'}
								onSubmit={handleSubmit}
								cn={cn}
							/>
						)
					case 1:
						return (
							<PhoneConfirmation
								onCodeResend={handleCodeResend}
								onSubmit={handlePhoneConfirmation}
								onBack={() => {
									userInterface.logout()
									setStep(0)
								}}
								phone={user.phone_number}
								cn={cn}
							/>
						)
					default:
						setStep(0)
						return null
				}
			})()}
			{step !== 1 && (
				<div className={s.signUpInfoContainer}>
					<SignUpInfo />
				</div>
			)}
		</>
	)
}
