import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import useResponsive from '../../../../../../utils/useResponsive'
import { Modal } from 'react-bootstrap'
import {
	PlacementShortWithStateAndFocus,
	PlacementWithOnlyOneShowingCount,
} from '../BookingAD'
import useCampaignData from '../../../../../../stores/campaignData'
import YandexMap from '../../../../../UtilityComponents/YandexMap'
import PickerDesktopLeftPannel from './PickerDesktopLeftPannel'
import PickerDesktopRightPannel, { CartIcon } from './PickerDesktopRightPannel'
import PlacementsSearchAndList from './PlacementsSearchAndList'
import PlacementDetails from './placement-details/PlacementDetails'
import PickerMobileBottomPannel from './PickerMobileBottomPannel'
import BookingsCart from './bookings-card/BookingsCart'
import {
	BookingState,
	BookingType,
	PlacementShort,
} from '../../../../../../stores/ADMarketTypes.types'
import { useConfirm } from '../../../../../UtilityComponents/ConfirmModalProvider'
import MobileModal from '../../../../../UtilityComponents/MobileModal'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'

const mapPlacementsShortToExtraField = (
	placements: PlacementShort[],
	selectedIds: number[]
): PlacementShortWithStateAndFocus[] => {
	return placements.map((el) => {
		let nextPlacementState: 'selected' | 'default' | 'unactive' = 'default'
		if (selectedIds.includes(el.pk)) {
			nextPlacementState = 'selected'
		} else if (!el.is_active) {
			nextPlacementState = 'unactive'
		}

		return {
			...el,
			state: nextPlacementState,
			focus: null,
		}
	})
}

const placementsTypeDefault = 'Все'

interface IRPlacementPicker {
	show: boolean
	onHide: () => any
	placements: PlacementShortWithStateAndFocus[]

	handleUpdateBookings: (
		bookings: { placement_showing_count_id: number }[]
	) => void
	bookings_type: 'indoor' | 'outdoor'
	buisnessCoords: number[] | null
	focusCenter?: [number, number]
}
export type PlacementPickerFilter = {
	search: string | null
	placementType: string
}
const PlacementPickerModal: FC<IRPlacementPicker> = ({
	show,
	onHide,
	placements,
	handleUpdateBookings,
	bookings_type,
	buisnessCoords,
	focusCenter,
}) => {
	const { isMobile } = useResponsive()
	const [campaign] = useCampaignData()

	const [searchString, setSearchString] = useState('')
	const [placementType, setPlacementType] = useState(placementsTypeDefault)

	const [isFilterChanged, setIsFilterChanged] = useState(false)

	const [dynamicCenter, setDynamicCenter] = useState<null | string[]>(null)
	const [focusPlacementID, setFocusPlacementID] = useState<null | number>(
		null
	)
	const [pickerPlacements, setPickerPlacements] = useState(placements)

	const allPlacementTypes = useMemo(() => {
		const uniqTypes = new Set(
			placements.map((placement) => placement.placement_type_name)
		)
		return Array.from(uniqTypes)
	}, [])

	const typedBookings = useMemo(() => {
		switch (bookings_type) {
			case 'indoor':
				return campaign.indoor_bookings
			case 'outdoor':
				return campaign.outdoor_bookings
			default:
				return campaign.booking
		}
	}, [campaign, bookings_type])
	const bookingIds = useMemo(
		() => typedBookings.map((el) => el.id),
		[typedBookings]
	)
	const searchPlacements = async () => {
		if (searchString) {
			const result = await authFetch<PlacementShort[]>({
				method: 'GET',
				url: `${BASE_URL}/adv_companies/placements/?door_type=${bookings_type}&q=${searchString}`,
			})

			const mappedPlacements = mapPlacementsShortToExtraField(
				result,
				bookingIds
			)

			if (mappedPlacements.length > 0) {
				setDynamicCenter([
					mappedPlacements[0].lat,
					mappedPlacements[0].lon,
				])
			}
			setPickerPlacements(mappedPlacements)
		} else {
			setPickerPlacements(placements)
		}
	}

	const [mobileCart, setMobileCart] = useState(false)
	const setIsExpandedRight = useRef<Dispatch<SetStateAction<boolean>>>()
	const setIsExpandedLeft = useRef<Dispatch<SetStateAction<boolean>>>()
	const [selectedBookings, setSelectedBookings] = useState<BookingType[]>(
		() => {
			switch (bookings_type) {
				case 'indoor':
					return campaign.indoor_bookings
				case 'outdoor':
					return campaign.outdoor_bookings
				default:
					return campaign.booking
			}
		}
	)

	const { confirm } = useConfirm()
	const handleFocusPlacement = (id: number) => {
		if (setIsExpandedLeft.current) setIsExpandedLeft.current(true)
		setPickerPlacements((prev) =>
			prev.map((placement) =>
				placement.pk === id
					? { ...placement, focus: 'focus' }
					: { ...placement, focus: null }
			)
		)
		setFocusPlacementID(id)
	}

	const handleSearchStringChange = (nextSearchString: string) => {
		if (!isFilterChanged) {
			setIsFilterChanged(true)
		}
		if (searchString !== nextSearchString) {
			setSearchString(nextSearchString)
		}
	}
	const handleTypeChange = (nextPlacementType: string) => {
		if (!isFilterChanged) {
			setIsFilterChanged(true)
		}
		if (nextPlacementType !== placementType) {
			setPlacementType(nextPlacementType)
		}
		const focusPickerPlacement = pickerPlacements.find(
			(el) => el.placement_type_name === placementType
		)
		if (focusPickerPlacement) {
			setDynamicCenter([
				focusPickerPlacement.lat,
				focusPickerPlacement.lon,
			])
		}
	}

	const handleSelectPlacement = (
		id: number,
		placement_detail: PlacementWithOnlyOneShowingCount
	) => {
		//изменяем selected state у определенного placement через итерацию по всем найденым местам
		setPickerPlacements((prev) =>
			prev.map((placement) =>
				placement.pk === id
					? {
							...placement,
							state: 'selected',
							placement_detail: placement_detail,
					  }
					: { ...placement }
			)
		)

		if (setIsExpandedRight.current) setIsExpandedRight.current(true)
		if (isMobile) setMobileCart(true)

		//добавляем новый букинг в корзину
		setSelectedBookings((prev) => [
			...prev,
			{
				id,
				placement: placement_detail,
				placement_showing_count:
					placement_detail?.placement_showing_count,
				state: BookingState.Draft,
				adjustment_msg: '',
			},
		])
	}

	const handleRemovePlacement = (id: number) => {
		if (setIsExpandedRight.current) {
			setIsExpandedRight.current(true)
		}
		setPickerPlacements((prev) =>
			prev.map((placement) =>
				placement.pk === id
					? {
							...placement,
							state: 'default',
							placement_detail: undefined,
					  }
					: { ...placement }
			)
		)
		setSelectedBookings((prev) => prev.filter((b) => b.placement.id !== id))
	}

	const handleRemoveAllPlacements = () => {
		setPickerPlacements((prev) =>
			prev.map((placement) => ({
				...placement,
				state:
					placement.state === 'selected'
						? 'default'
						: placement.state,
				placement_detail: undefined,
			}))
		)
		setSelectedBookings([])
	}

	const handleConfirm = async () => {
		await handleUpdateBookings(
			selectedBookings.map((booking) => ({
				placement_showing_count_id: booking.placement_showing_count.id,
			}))
		)
		onHide()
	}

	const handleClose = async () => {
		//Проверка на изменения
		const current_ids: number[] = typedBookings.map((b) => b.id)
		let withChanges = false
		selectedBookings.forEach((b) => {
			if (!current_ids.includes(b.id)) withChanges = true
		})
		if (!withChanges) {
			if (typedBookings.length === selectedBookings.length)
				return onHide()
		}
		const res = await confirm({
			text: 'Вы изменили список добавленных рекламных мест. Вы уверены, что хотите закрыть окно без подтверждения?',
			title: 'Подтвердить изменения?',
			closeButton: true,
			acceptText: 'Подтвердить изменения',
			declineText: 'Закрыть',
		})
		res ? handleConfirm() : onHide()
	}

	const filtredPickerPlacements = useMemo(() => {
		return placementType !== placementsTypeDefault
			? pickerPlacements.filter(
					(el) => el.placement_type_name === placementType
			  )
			: pickerPlacements
	}, [pickerPlacements, placementType])

	const isDetailedPlacementAdded = useMemo(() => {
		if (!focusPlacementID) return false

		const selectedBooking = selectedBookings.find(
			(b) => b.placement.id === focusPlacementID
		)

		return !!selectedBooking
	}, [focusPlacementID, selectedBookings])

	useEffect(() => {
		if (isFilterChanged) {
			searchPlacements()
		}
	}, [searchString, isFilterChanged])

	useEffect(() => {
		if (isMobile) {
			document.documentElement.style.overflow = 'hidden'
		}
		return () => {
			document.documentElement.style.overflow = ''
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	//Смена центра карты при клике на место на мобилке
	useEffect(() => {
		if (!focusPlacementID) return setDynamicCenter(null)
		const focus = placements.find((p) => p.pk === focusPlacementID)
		//дополнительное смещения центра карты из-за левого выпадающего меню
		if (focus) {
			setDynamicCenter([focus.lat, focus.lon])
		}
	}, [focusPlacementID]) // eslint-disable-line react-hooks/exhaustive-deps
	return (
		<Modal show={show} onHide={handleClose} fullscreen animation={false}>
			<Modal.Header
				closeButton
				style={{
					padding: isMobile ? '10px 12px' : '18px 24px',
				}}
			>
				<Modal.Title
					as={'h5'}
					style={{ fontSize: isMobile ? '12px' : '20px' }}
				>
					{`Добавление мест ${
						bookings_type === 'outdoor' ? 'наружной' : 'внутренней'
					} рекламы`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className={'p-0 overflow-hidden position-relative d-flex'}
			>
				{!isMobile && (
					<PickerDesktopLeftPannel>
						{(setIsExpanded) => {
							setIsExpandedLeft.current = setIsExpanded
							return focusPlacementID ? (
								<PlacementDetails
									isAdded={isDetailedPlacementAdded}
									placementID={focusPlacementID}
									onBack={() => setFocusPlacementID(null)}
									handleRemovePlacement={
										handleRemovePlacement
									}
									handleSelectPlacement={
										handleSelectPlacement
									}
								/>
							) : (
								<PlacementsSearchAndList
									initialSearchString={searchString}
									placementType={placementType}
									placementTypes={allPlacementTypes}
									placements={filtredPickerPlacements}
									handleFocusPlacement={handleFocusPlacement}
									onSearchStringChange={
										handleSearchStringChange
									}
									onTypeChange={handleTypeChange}
								/>
							)
						}}
					</PickerDesktopLeftPannel>
				)}
				<YandexMap
					width={'100%'}
					height={isMobile ? '95%' : '100%'}
					zoom={14}
					data={pickerPlacements}
					onPlacemarkClick={handleFocusPlacement}
					focusCenter={focusCenter}
					buisnessCoords={buisnessCoords}
					withZoomControls={isMobile}
					changeCenter={dynamicCenter}
					bigBuisnessLabel={campaign.company.actual_location}
					isMobile={isMobile}
					placementsType={'all'}
				/>
				{isMobile ? (
					<>
						<CartIcon
							isMobile={isMobile}
							isExpanded={mobileCart}
							setIsExpanded={setMobileCart}
							count={selectedBookings.length}
						/>
						<MobileModal
							show={mobileCart}
							onHide={() => setMobileCart(false)}
							title={'Добавленные места'}
							zIndex={2000}
							paddingContent={'0px'}
						>
							<BookingsCart
								selectedBookings={selectedBookings}
								handleRemoveAllPlacements={
									handleRemoveAllPlacements
								}
								handleRemovePlacement={handleRemovePlacement}
								handleConfirm={handleConfirm}
								isMobile={isMobile}
							/>
						</MobileModal>
					</>
				) : (
					<PickerDesktopRightPannel
						count={selectedBookings.length}
						isMobile={isMobile}
					>
						{(setIsExpanded) => {
							setIsExpandedRight.current = setIsExpanded
							return (
								<BookingsCart
									selectedBookings={selectedBookings}
									handleRemoveAllPlacements={
										handleRemoveAllPlacements
									}
									handleRemovePlacement={
										handleRemovePlacement
									}
									handleConfirm={handleConfirm}
									isMobile={isMobile}
								/>
							)
						}}
					</PickerDesktopRightPannel>
				)}

				{isMobile && (
					<PickerMobileBottomPannel
						show={true}
						type={focusPlacementID ? 'details' : 'search'}
						withCloseButton={focusPlacementID ? true : false}
						closeButtonAction={() => setFocusPlacementID(null)}
					>
						{({ isExpanded: mobileIsExpanded }) =>
							focusPlacementID ? (
								<PlacementDetails
									isAdded={isDetailedPlacementAdded}
									placementID={focusPlacementID}
									onBack={() => setFocusPlacementID(null)}
									handleRemovePlacement={
										handleRemovePlacement
									}
									handleSelectPlacement={
										handleSelectPlacement
									}
								/>
							) : (
								<PlacementsSearchAndList
									initialSearchString={searchString}
									placementType={placementsTypeDefault}
									placementTypes={allPlacementTypes}
									onSearchStringChange={
										handleSearchStringChange
									}
									onTypeChange={handleTypeChange}
									placements={filtredPickerPlacements}
									handleFocusPlacement={handleFocusPlacement}
									mobileIsExpanded={mobileIsExpanded}
								/>
							)
						}
					</PickerMobileBottomPannel>
				)}
			</Modal.Body>
		</Modal>
	)
}

export default PlacementPickerModal
